//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-564:_6276,_3220,_3748,_5244,_3884,_6792,_4168,_3392;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-564')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-564', "_6276,_3220,_3748,_5244,_3884,_6792,_4168,_3392");
        }
      }catch (ex) {
        console.error(ex);
      }